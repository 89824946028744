/* @media (max-width: 600px) {
  .resume-img {
    height: 50vh;
    width: 50vh;
  }
} */
/* .resume-container {
  position: relative;
  height: 100%;
  width: 100%;
} */

.pdf-paragraph {
  padding: 20px;
  align-items: center;
}

@media (max-width: 600px) {
  .pdf-container {
    height: 600px;
  }
}
